import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { IconUpdate, IconGroup } from 'components/Icon/SVGIcons';
import { Rating } from 'components/Rating';
import { fromScreen } from 'utils/media-query/responsive.util';
import { getName } from 'utils';
import { BreadCrumb } from 'shared/CourseLanding/Detail/BreadCrumb';

const ROUTE_COURSE = {
  'Khóa IELTS cơ bản': 'ielts-co-ban',
  'Khóa IELTS nâng cao': 'ielts-nang-cao',
  'Khóa IELTS cấp tốc': 'ielts-cap-toc',
  'Khóa TOEIC': 'course-landing/toeic-and-pronunciation',
  'Tiếng Anh Giao Tiếp': 'course-landing/toeic-and-pronunciation',
  'Khóa SAT': 'course-landing/sat-and-gmat-gre',
  'Khóa GMAT/GRE': 'course-landing/sat-and-gmat-gre',
  'Khoá Junior': 'ielts-junior'
};

export const HeaderV3 = React.memo(
  withHomePageSection(
    props => {
      const { level, rating, teachers } = props;
      return (
        <>
          <BreadCrumb
            links={[
              {
                text: 'Trang Chủ',
                to: '/',
                tabletOnly: true
              },
              {
                text: 'Khoá học IELTS',
                to: '/khoa-hoc-ielts',
                tabletOnly: true
              },
              {
                text: getName(level.classGroup),
                to: `/${ROUTE_COURSE[level.classGroup]}`
              },
              {
                text: getName(level.levelDisplay),
                to: level?.linkLevel || '/'
              }
            ]}
          />
          <Container>
            <TextContainer
              id="course-landing-detail-header-text"
              className="header-course"
            >
              <div className="header-course-info">
                <div className="header-title">
                  <Typography
                    v3
                    tabletVariant="bold/32-40"
                    variant="bold/24-32"
                    className="rating"
                    as="h1"
                  >
                    {getName(level.levelDisplay)}{' '}
                    <Rating stars={rating.starsInFloat} />
                  </Typography>
                  <Typography variant="regular/16-28">
                    {level.levelDescription}
                  </Typography>
                </div>
                <div className="header-content">
                  <div className="course-info">
                    {level.isBestSeller && (
                      <div className="tag">Bestseller</div>
                    )}
                  </div>
                  {Array.isArray(teachers) && teachers.length > 0 && (
                    <div className="teacher-name">
                      Giáo viên:{' '}
                      {teachers
                        .map(teacher => teacher.displayName)
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                  )}

                  <div className="last-updated-at">
                    <Typography
                      variant="regular/14-20"
                      style={{ color: colorsV2.green100 }}
                    >
                      <IconUpdate />
                      {level.updatedAt || 'Updated in December 2022'}
                    </Typography>
                    <Typography
                      style={{ color: colorsV2.black80 }}
                      variant="regular/14-20"
                    >
                      <IconGroup />
                      {level.numOfStudent} học viên đã học
                    </Typography>
                  </div>
                </div>
              </div>
            </TextContainer>
            <div id="course-landing-detail-header-slot" />
          </Container>
        </>
      );
    },
    {
      style: {
        background: colorsV2.black5,
        zIndex: 2,
        paddingTop: '32px',
        paddingBottom: '32px'
      },
      selfish: true
    }
  )
);

const Container = styled.div`
  margin-top: 32px;
  #course-landing-detail-header-slot {
    display: block;
  }
  .header-video {
    .thumbnail {
      margin-bottom: 0;
    }
  }

  display: grid;

  .header-content {
    display: grid;
    grid-auto-flow: row;
    gap: 4px;
    .course-info {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 8px;
      grid-template-columns: max-content;
    }
    .tag {
      padding: 2px 4px;
      border-radius: 4px;
      background: ${colorsV2.primary100};
      border-radius: 4px;
      color: ${colorsV2.white100};
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    .teacher-name {
      color: ${colorsV2.black100};
      font-size: 16px;
      line-height: 28px;
    }
    .last-updated-at {
      margin-top: 4px;
      margin-bottom: 16px;
      display: grid;
      gap: 12px;
      > p {
        display: grid;
        gap: 8px;
        grid-template-columns: max-content 1fr;
      }
      svg {
        font-size: 20px;
      }
    }
  }

  ${fromScreen(458)} {
    .last-updated-at {
      > p:first-child {
        order: 2;
      }
    }
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 240px;
    gap: 20px;
  }
  ${fromScreen(1144)} {
    #course-landing-detail-header-slot {
      margin-top: -56px;
    }
    .last-updated-at {
      grid-template-columns: max-content max-content;
      gap: 60px !important;
      margin-bottom: 0;
      > p:first-child {
        order: 0;
      }
    }
    grid-template-columns: 1fr 340px;
    gap: 28px;
  }
`;

const TextContainer = styled.div`
  .header-title {
    display: grid;
    gap: 4px;
  }
  ${fromScreen(776)} {
    .header-title {
      .rating {
        display: flex;
        > div {
          margin-left: 8px;
        }
      }
    }
  }
`;

HeaderV3.propTypes = {
  level: PropTypes.shape({
    levelDisplay: PropTypes.string,
    levelDescription: PropTypes.string
  })
};
